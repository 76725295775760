import { render, staticRenderFns } from "./educationPlanInfo.vue?vue&type=template&id=d6c056b6"
import script from "./educationPlanInfo.vue?vue&type=script&lang=js"
export * from "./educationPlanInfo.vue?vue&type=script&lang=js"
import style0 from "./educationPlanInfo.vue?vue&type=style&index=0&id=d6c056b6&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\psm\\PSMMobile\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d6c056b6')) {
      api.createRecord('d6c056b6', component.options)
    } else {
      api.reload('d6c056b6', component.options)
    }
    module.hot.accept("./educationPlanInfo.vue?vue&type=template&id=d6c056b6", function () {
      api.rerender('d6c056b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sop/edu/result/educationPlanInfo.vue"
export default component.exports